<template>
    <div class="wrapper-container">
<!--        <common-header>通過郵箱找回密碼</common-header>-->
        <div class="register-container">
            <div>
                <div class="inp-container">
                    <div class="password-container">
                        <span class="password-tip">賬號:</span>
                        <div  class="wrapper wrapper-2 hide"><input max-length="20" v-model.trim="accountInfo.account" placeholder="请输入账号" class="inp inp-password" :type="eyeStatus['eye1'] ? 'password' : 'text'"></div>
                    </div>
                    <div class="password-container">
                        <span class="password-tip">郵箱:</span>
                        <div  class="wrapper wrapper-2 hide"><input max-length="20" v-model.trim="accountInfo.email" placeholder="请输入邮箱" class="inp inp-password" :type="eyeStatus['eye2'] ? 'password' : 'text'"></div>
                    </div>
                </div>
            </div>
            <div class="login" @click="findPassword">郵箱找回密碼</div>
        </div>
        <van-dialog width="92%" v-model="show" title="" :show-confirm-button="false">
            <div class="dialog">
                <div class="title">請輸入驗證碼</div>
                <div class="tip-text">
                    驗證碼已發送{{accountInfo.email}},請查收,請在5分鐘內使用。
                </div>
                <ul class="code">
                    <li class="item" v-for="(code, index) in codeList" :key="index">
                        <input :ref="`inpRef${index}`" @input="inputChange($event, index)" maxlength="1" type="text" v-model.trim="codeList[index]">
                    </li>
                </ul>
                <div @click="certainCode" class="certain-btn">
                    確認
                </div>
                <span @class="findPassword" class="re-get">重新獲取</span>
            </div>
        </van-dialog>
        <van-overlay :show="showLoading" @click="showLoading = false">
        </van-overlay>
    </div>
</template>
<script>
import {mapState} from 'vuex'
// import CommonHeader from '@/components/header.vue'
import {validateEmail} from '@/utils/tools'
export default {
    data() {
        return {
            showLoading: false,
            codeList: ['', '', '', '', '', ''],
            show: false,
            isClose: false,
            accountInfo: {
                account: '',
                email: ''
            },
            passwordObj: {
                eye1: '',
                eye2: ''
            },
            eyeStatus: {
                eye1: false,
                eye2: false
            },
            eyeCopyPassword: {
                eye1: '12345678',
                eye2: ''
            }
        }
    },
    components: {
        // CommonHeader
    },
    computed: {
        ...mapState({
            realNameInfo: state => state.layout.realNameInfo
        })
    },
    mounted() {
        // 獲取傳遞過來的手機號碼
        let num = this.$route.query['phone']
        if(num) {
            this.accountInfo.account = num
        }
    },
    methods: {
        inputChange(e, index) {
            if(index < 5 && e.target.value !== '' && !this.codeList[index + 1]) {
                this.$refs[`inpRef${index + 1}`][0].focus()
            }
        },
        certainCode() {
            // 確認驗證碼
            this.$store.dispatch('verifyCode', {
                code: this.codeList.join(''),
                toEmail: this.accountInfo.email
            }).then(res => {
                console.log('驗證碼確認,', res)
                this.show = false
                this.$router.push(`/modifyPassword?from=reset&phone=${this.accountInfo.account}`)
            })
        },
        findPassword() {

            // 校验密码
            const {email} = this.accountInfo
            if(!validateEmail(email)) {
                this.$toast({
                    message: '請按照正確格式輸入郵箱'
                })
                return
            }
            this.showLoading = true;

            let params = {
                telephone: this.accountInfo.account,
                toEmail: this.accountInfo.email
            }

            this.$store.dispatch('findPassword', params).then(res => {
                if(res.code === 200) {
                    // 彈出彈窗
                    this.showLoading = false;
                    this.show = true
                    this.$nextTick(() => {
                        this.$refs['inpRef0'][0].focus()
                    })
                }
            })
        },
        toggleEye(num) {
            this.eyeStatus[`eye${num}`] = !this.eyeStatus[`eye${num}`]
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper-container {
        min-height: 100vh;
        background: #fff;
    }
    .register-container {
        width: 100%;
        padding: 80px 40px 0 35px;
        box-sizing: border-box;
        background: #fff;
        .to-register {
            color: #00C2C1;
            font-size: 16px;
            position: absolute;
            top: 22px;
            right: 22px;
        }
        .appName {
            font-size: 28px;
            color: #02BCBD;
            font-family: PingFangSC-bold;
            margin-bottom: 60px;
        }

        .tip {
            font-size: 18px;
            color: #ccc;
            font-family: PingFangSC-regular;
            text-align: left;
            height: 40px;
        }

        .inp-container {
            display: flex;
            flex-direction: column;

            .wrapper {
                display: flex;
                align-items: center;
                position: relative;
                &-1::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/images/login-user.png');
                    background-size: 100%;
                    position: absolute;
                }

                &-2::before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/images/login-lock.png');
                    background-size: 100%;
                    position: absolute;
                }

                &.hide {
                    .inp {
                        // padding-left: 0;
                    }
                    &::before {
                        background: none;
                    }
                }

                .inp {
                    width: 300px;
                    height: 44px;
                    border-bottom: 1px solid #eee;
                    padding-left: 80px;
                    padding-right: 24px;
                    box-sizing: border-box;
                    font-size: 16px;
                    color: #101010;
                    box-sizing: border-box;
                }
            }

            .password-tip {
                font-size: 14px;
                // margin-top: 10px;
            }

            .password-container {
                display: flex;
                position: relative;
                align-items: center;
                .password-tip {
                    display: block;
                    width:80px;
                    flex-shrink: 0;
                    font-size: 14px;
                    color: #000;
                    position: absolute;
                    left: 0;
                    z-index: 100;
                    text-align: left;
                }
                .eye {
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/images/visibility.png');
                    background-size: 100%;
                    position: absolute;
                    right: 0;
                    top: 10px;
                    &.close-eye {
                        background: url('../../assets/images/unVisibility.png');
                        background-size: 100%;
                    }
                }
            }
        }

        .login {
            width: 210px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            color: #fff;
            background: #02BCBD;
            margin: 100px auto 0;
            border-radius: 20px;
        }

        .read {
            margin-top: 50px;
            display: flex;
            width: 300px;
            .choose {
                width: 20px;
                height: 20px;
                border: 1px solid #ccc;
                display: block;
                border-radius: 50%;
                margin-right: 2px;

                &.active {
                    background: url('../../assets/images/checked.png');
                    background-size: 100%;
                    border: none;
                }
            }

            .text {
                text-align: left;
                .protocol {
                    color: #02BCBD;
                }
            }
        }

    }

    .dialog {
        padding: 36px 28px 23px 28px;
        box-sizing: border-box;
        .title {
            font-size: 20px;
            color: #333;
        }

        .tip-text {
            font-size: 14px;
            color: #999;
            margin-top: 9px;
            margin-bottom: 40px;
        }

        .code {
            display: flex;
            justify-content: center;
            align-items: center;
            .item {
                background: #EEE;
                flex-shrink: 0;
                width: 44px;
                height: 44px;
                border-radius: 6px;
                margin: 0 4px;

                input {
                    font-size: 20px;
                    color: #333;
                    background: #eee;
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    text-align: center;
                }
            }
        }

        .certain-btn {
            width: 110px;
            height: 40px;
            background: #00C2C1;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 16px;
            margin: 55px auto 23px;
        }

        .re-get {
            color: #1890FF;
            font-size: 14px;
        }
    }
</style>
